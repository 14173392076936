<template>
  <b-sidebar id="sidebar-package-service" no-header :visible="!!value" @hidden="$emit('input', null)">
    <template #default="{ hide }">
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">{{ $t('service.package_service.add_a_service') }}</h5>
        <feather-icon class="ml-1 cursor-pointer" icon="XIcon" size="16" @click="hide" />
      </div>
      <validation-observer ref="packageServiceFormRef" tag="form" class="p-5" @submit.prevent="onSubmit">
        <b-form-group :label="$t('navigation.categories')" label-for="categories">
          <category-tree-select id="categories" v-model="categories" :lang="$store.state.appConfig.lang" disable-branch-nodes />
        </b-form-group>
        <validation-provider #default="{ errors }" :name="$tc('common.service')" rules="required" class="validation-required">
          <b-form-group :label="$tc('common.service')" label-for="organizationHelicopter">
            <v-select
              v-model="selectedPackageService"
              input-id="organizationHelicopter"
              :placeholder="$tc('common.service')"
              label="id"
              :options="servicesOptions"
              :searchable="false"
              @open="fetchServicesOptions()"
              @search="
                search => {
                  searchLoader = true
                  searchServices(search)
                }
              "
            >
              <template v-slot:no-options="{ search }">
                <b-spinner v-if="searchLoader" small />
                <template v-else-if="search">{{ $t('common.no_results_found_for', { search }) }}</template>
                <template v-else>{{ $t('common.no_results_found') }}</template>
              </template>

              <template #selected-option="{ name }">
                {{ name | trans }}
              </template>
              <template #option="{ name }">
                {{ name | trans }}
              </template>

              <!-- TODO: component + css for pagination -->
              <template v-slot:list-footer="{ search }">
                <li v-if="servicesDataMeta.totalItems > 12 && !searchLoader && servicesDataMeta.lastPage > 1" class="pagination">
                  <button
                    class="w-100"
                    type="button"
                    :disabled="!servicesDataMeta.previousPage"
                    @click="fetchServicesOptions(servicesDataMeta.previousPage, search || null)"
                    v-text="$t('common.previous')"
                  />
                  <button
                    class="w-100"
                    type="button"
                    :disabled="!servicesDataMeta.nextPage"
                    @click="fetchServicesOptions(servicesDataMeta.nextPage, search || null)"
                    v-text="$t('common.next')"
                  />
                </li>
              </template>
            </v-select>
            <small class="text-danger">{{ errors[0] }}</small>
          </b-form-group>
        </validation-provider>

        <!-- submit and reset -->
        <app-form-actions
          class="mt-5"
          form-name="deposit-zone-form"
          show-submit-and-next
          :submit-disabled="packageServiceLoading"
          @cancel="hide"
          @reset="selectedPackageService = null"
          @save-and-next="saveAndNext"
        />
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import { computed, defineComponent, inject, ref, watch } from '@vue/composition-api'
import { debounce, omit } from 'lodash'
import { mapActions } from 'vuex'

import AppFormActions from '@/components/AppFormActions.vue'
import CategoryTreeSelect from '@/components/CategoryTreeSelect.vue'
import { fetchProductsRequest } from '@/request/globalApi/requests/productRequests'

export default defineComponent({
  name: 'PackageServiceForm',

  components: {
    AppFormActions,
    CategoryTreeSelect,
  },

  props: {
    value: {
      type: String,
      default: null,
    },
    service: {
      type: Object,
      default: null,
    },
  },

  setup(props, ctx) {
    const { $i18n, toaster } = ctx.root
    const $emit = ctx.emit

    const product = inject('product')

    const packageServiceFormRef = ref(null)
    const packageServiceLoading = ref(false)
    const searchLoader = ref(false)
    const servicesOptions = ref([])
    const selectedPackageService = ref(null)
    const categories = ref([])

    const servicesDataMeta = ref({
      firstPage: 1,
      lastPage: 1,
      nextPage: 1,
      previousPage: null,
      totalItems: 0,
    })

    const serviceVariantIds = computed(() => product.value.services.map(service => service.id))

    const fetchServicesOptions = (page = null, searchQuery = null) => {
      fetchProductsRequest({
        lightResponse: true,
        typologies: ['service'],
        categoriesIds: categories.value.map(cat => cat.id),
        page,
        searchQuery,
      }).then(({ data }) => {
        searchLoader.value = false
        servicesOptions.value = data.products.filter(service => service.mainVariant && !serviceVariantIds.value.includes(service.mainVariant.id))
        servicesDataMeta.value = omit(data, 'products')
      })
    }

    // TODO search mode (options not found)
    const searchServices = debounce(async search => {
      fetchServicesOptions(null, search)
    }, 500)

    watch(
      () => props.value,
      value => {
        if (!value) {
          packageServiceFormRef.value.reset()
          selectedPackageService.value = null
        }
      },
    )

    const onSubmit = async (close = true) => {
      if (selectedPackageService.value && selectedPackageService.value.id) {
        packageServiceLoading.value = true

        product.value
          .postPackageService({ variantId: selectedPackageService.value.mainVariant.id })
          .then(() => {
            product.value.fetchPackageServices()
            if (close) {
              $emit('input', null)
            }
          })
          .finally(() => {
            packageServiceLoading.value = false
          })
      } else {
        toaster($i18n.t('alert.fill_required_fields'), 'danger', 'AlertTriangleIcon')
      }
    }

    const saveAndNext = () => {
      onSubmit(false)
      selectedPackageService.value = null
      packageServiceFormRef.value.reset()
    }

    return {
      product,
      categories,
      searchLoader,
      packageServiceFormRef,
      serviceVariantIds,
      selectedPackageService,
      packageServiceLoading,
      fetchServicesOptions,
      searchServices,
      servicesOptions,
      servicesDataMeta,
      onSubmit,
      saveAndNext,
    }
  },

  methods: {
    ...mapActions('organizationHelicopter', ['fetchOrganizationHelicopters']),
  },
})
</script>
