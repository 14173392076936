<template>
  <section class="p-2">
    <h2>{{ $t('service.package_service.title') }}</h2>
    <p>{{ $t('service.package_service.introduction') }}</p>

    <!-- TABLE TOP -->
    <app-data-table-top table-name="package-services" class="mb-1 mt-3" condensed="lg" size="md" :show-add="$route.name !== 'productShow'" :show-per-page="false" @table-add="addForm = 'add'" />

    <b-table
      class="w-100"
      :items="product.services"
      :fields="fields"
      primary-key="id"
      show-empty
      responsive
      striped
      :empty-text="$t('common.no_record_found')"
    >
      <!-- CELL -->
      <template #cell(id)="{ item }">
        {{ `#${item.id}` }}
      </template>
      <template #cell(services)="{ item }">
        {{ item.productName | trans }}
      </template>

      <template #cell(actions)="{ item }">
        <div align="right">
          <app-data-table-actions
            :table-name="`package-service-${item.id}`"
            display-mode="inline-icon"
            :show-add="false"
            :show-duplicate="false"
            :show-show="false"
            :show-edit="false"
            :show-delete="$can('SERVICE_EDIT')"
            @delete="removePackageService(item.id)"
          />
        </div>
      </template>
    </b-table>

    <b-card no-body class="mb-0 mt-5">
      <validation-observer ref="packageDiscountFormRef" tag="form" @submit.prevent="onSubmit()">
        <b-card-title class="mb-2">
          {{ $t('service.package_service.discount.title') }}
        </b-card-title>
        <b-row>
          <!-- Discount value -->
          <b-col cols="12" md="6">
            <app-input
              id="discountValue"
              v-model.number="product.mainVariant.discount"
              :disabled="$route.name === 'productShow'"
              :label="$t('service.package_service.discount.value')"
              type="number"
              step="0.01"
              rules="positive"
              :append="append[product.mainVariant.discountMode]"
            />
          </b-col>

          <!-- TODO: Discount mode after reflexion about discount MP (how apply the vat ??) -->
          <!-- <b-col cols="12" md="6">
            <enum-select
              id="discountMode"
              v-model="product.mainVariant.discountMode"
              :label="$t('service.package_service.discount.mode')"
              enum-class="PriceMode"
              required
            />
          </b-col> -->
        </b-row>

        <footer-form-service
          v-if="$route.name === 'productShow'"
          class="mt-3"
          previous
          next
          :disabled="saveLoader"
          @click:previous="$emit('previous-tab')"
          @click:next="$emit('next-tab')"
        />
        <footer-form-service
          v-else
          class="mt-3"
          previous
          save
          save-next
          :disabled="saveLoader"
          @click:previous="$emit('previous-tab')"
          @click:save-next="nextRedirection = true"
        />
      </validation-observer>
    </b-card>

    <package-service-form v-model="addForm" :service="selectedService" />
  </section>
</template>

<script>
import { defineComponent, inject, ref } from '@vue/composition-api'
import AppDataTableTop from '@/components/AppDataTableTop.vue'
import AppDataTableBottom from '@/components/AppDataTableBottom.vue'
import AppDataTableActions from '@/components/AppDataTableActions.vue'
import PackageServiceForm from '../component/PackageServiceForm.vue'
import FooterFormService from '../component/FooterFormService.vue'

export default defineComponent({
  name: 'PackageManagement',

  components: {
    PackageServiceForm,
    AppDataTableTop,
    AppDataTableBottom,
    FooterFormService,
    AppDataTableActions,
  },

  setup(_props, ctx) {
    const { $i18n, $bvModal, $store, alert } = ctx.root
    const $emit = ctx.emit

    const product = inject('product')
    product.value.fetchPackageServices(ctx.root.$route.params.service_id, ctx.root.$route.query.vid)
    product.value.fetchPackageDiscount(ctx.root.$route.params.service_id, ctx.root.$route.query.vid)

    const addForm = ref(null)
    const selectedService = ref(null)
    const saveLoader = ref(false)
    const nextRedirection = ref(false)
    const packageDiscountFormRef = ref(null)

    const fields = [
      { key: 'id' },
      { key: 'services', label: $i18n.tc('common.service', 2), thStyle: 'width: 100%' },
      { key: 'actions', label: $i18n.tc('action.title', 2), thClass: 'text-center' },
    ]
    const append = {
      currency: $store.state.appConfig.currency,
      percent: '%',
    }

    const removePackageService = id => {
      $bvModal
        .msgBoxConfirm($i18n.t('alert.delete_confirmation.message'), {
          title: $i18n.t('alert.delete_confirmation.title'),
          size: 'sm',
          okVariant: 'primary',
          okTitle: $i18n.t('common.yes'),
          cancelTitle: $i18n.t('common.no'),
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(async isConfirmed => {
          if (isConfirmed) {
            await product.value.deletePackageService(id)
            alert($i18n.t('alert.delete.success'))
            await product.value.fetchPackageServices()
          }
        })
    }

    const onSubmit = () => packageDiscountFormRef.value.validate().then(validForm => {
      if (validForm) {
        saveLoader.value = true
        product.value
          .patchDiscount()
          .then(() => {
            if (nextRedirection.value) {
              $emit('next-tab')
              nextRedirection.value = false
            }
          })
          .finally(() => {
            saveLoader.value = false
          })
      }
    })

    return {
      product,
      addForm,
      selectedService,
      removePackageService,
      packageDiscountFormRef,
      onSubmit,
      saveLoader,
      nextRedirection,
      fields,
      append,
    }
  },
})
</script>

<style lang="scss" scoped>
.repeater-form {
  overflow: hidden;
  transition: 0.35s height;
}
[dir] .form-label-group {
  margin-bottom: 0;
}
</style>
